import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { invalidateTranslations } from 'state/translationSlice';
import { Dispatch } from '@reduxjs/toolkit';

export const languages = [
	{ lang: 'en', code: 'en-us', name: 'English' },
	{ lang: 'de', code: 'de', name: 'Deutsch' }
	// { lang: 'sk', code: 'sk', name: 'Slovensky' }
];
let dispatch: null | Dispatch = null;

export function setDispatcher(d: Dispatch) {
	dispatch = d;
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: languages.map((lang) => lang.lang),
		partialBundledLanguages: true,
		ns: [],
		resources: {},
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false
		},
		saveMissing: true,
		missingKeyHandler: (lngs, ns, key) => {
			sendHttpRequest('POST', '/translations/missing/en/' + key, null).then(() => {
				dispatch?.(invalidateTranslations());
			});
		}
	});

export default i18n;
