import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'state/userSlice';
import menuReducer from 'state/menuSlice';
import componentReducer from 'state/componentSlice';
import socketReducer from 'state/socketSlice';
import notificationReducer from 'state/notificationSlice';
import promotionReducer from 'state/promotionSlice';
import translationReducer from 'state/translationSlice';
import totalGpPercentageReducer from 'state/totalGpPercentageSlice';
import gridFilterReducer from 'state/gridFilterSlice';

export const store = configureStore({
	reducer: {
		user: userReducer,
		menu: menuReducer,
		components: componentReducer,
		socket: socketReducer,
		notification: notificationReducer,
		promotion: promotionReducer,
		translation: translationReducer,
		totalGpPercentage: totalGpPercentageReducer,
		gridFilter: gridFilterReducer
	}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
