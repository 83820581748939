import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface gridFilterState {
	keys: string[];
	filterData: { [key: string]: object };
}

const filterDataKey = 'filterData';

const filterKeys = JSON.parse(localStorage.getItem(`${filterDataKey}.keys`) ?? '[]');

const initialState: gridFilterState = {
	keys: filterKeys,
	filterData: filterKeys
		.map((key: string) => JSON.parse(localStorage.getItem(`${filterDataKey}.${key}`) ?? '{}'))
		.reduce((acc: { [key: string]: object }, obj: object, i: number) => {
			acc[filterKeys[i]] = obj;
			return acc;
		}, {})
};

const slice = createSlice({
	name: 'gridFilter',
	initialState,
	reducers: {
		setGridFilter(state, action: PayloadAction<{ key: string; data: object }>) {
			state.filterData[action.payload.key] = action.payload.data;
			state.keys = Object.keys(state.filterData);
			localStorage.setItem(`${filterDataKey}.keys`, JSON.stringify(state.keys));
			localStorage.setItem(
				`${filterDataKey}.${action.payload.key}`,
				JSON.stringify(action.payload.data)
			);
		},
		emptyGridFilter(state, action: PayloadAction<string>) {
			if (localStorage.getItem(`${filterDataKey}.${action.payload}`)) {
				delete state.filterData[action.payload];
				state.keys = Object.keys(state.filterData);
				localStorage.setItem(`${filterDataKey}.keys`, JSON.stringify(state.keys));
				localStorage.removeItem(`${filterDataKey}.${action.payload}`);
			}
		}
	}
});

export default slice.reducer;
export const { setGridFilter, emptyGridFilter } = slice.actions;
