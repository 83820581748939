import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import { DataSaveStatus, Product, PromotionStructure, BaseColumns } from 'utils/types';
import PromotionStockItem from 'components/Forms/Promotion/PromotionStockItem';
import PromotionCell from 'components/Forms/Promotion/PromotionCell';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/parts/Loading';
import { RootState } from 'state/store';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'components/Forms/FormComponents/Checkbox';
import {
	baseColumnNames,
	calculateValue,
	getBaseColumns,
	getTotalGpPercentage
} from 'utils/promotion';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { addNotification } from 'state/notificationSlice';
import { randomHash } from 'utils/string';
import { setTotalGpPercentage } from 'state/totalGpPercentageSlice';

export default function PromotionProduct(props: {
	template: string;
	product: Product;
	retail_price: string;
	index: number;
	structures: PromotionStructure[];
	showStockItems: boolean;
	i_business: number;
	sumPrice: (product: Product, c?: string) => number;
	countries: string[];
	omitColumns: string[];
	setDataSaveStatus: (s: DataSaveStatus) => void;
	removeProduct: (productId: string, i_template: string) => void;
	listWeekFrom: string;
	listWeekTo: string;
	editDisabledByStatus: boolean;
	canClose: boolean;
	listStatus: string;
	iList: number;
	triggerDataRefresh: () => void;
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const allCountries = useSelector((state: RootState) => state.user.allCountries);

	const [product, setProduct] = useState(props.product);
	const [saveTimer, setSaveTimer] = useState(null as NodeJS.Timeout | null);
	const [savingLock, setSavingLock] = useState(false);

	const [namesLinked, setNamesLinked] = useState(
		props.product.countries.every((c) => c.product_name === props.product.countries[0].product_name)
	);

	const [baseColumns, setBaseColumns] = useState([] as BaseColumns[]);

	const [needsRecalculation, setNeedsRecalculation] = useState(false);

	useEffect(() => {
		setProduct(props.product);
	}, [props.product]);

	useEffect(() => {
		setBaseColumns(
			getBaseColumns(allCountries, product, props.sumPrice, props.listWeekFrom, props.listWeekTo)
		);
	}, [allCountries, product, props]);

	const [editedColumnsDef, setEditedColumns] = useState([] as string[]);
	let editedColumns = editedColumnsDef;

	const addEditedColumn = useCallback(
		(column: string) => {
			if (!editedColumns.includes(column)) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				editedColumns = [...editedColumns, column];
				setEditedColumns([...editedColumns]);
			}
		},
		[editedColumns]
	);

	const removeEditedColumn = useCallback(
		(column: string) => {
			if (editedColumns.includes(column)) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				editedColumns = editedColumns.filter((c) => c !== column);
				setEditedColumns([...editedColumns]);
			}
		},
		[editedColumns]
	);

	const structure = useMemo(() => {
		return props.structures.find((s) => s.i_template === product.i_template);
	}, [product.i_template, props.structures]);

	const countriesTabs = structure?.countries;

	const setValue = useCallback(
		(
			item_id: number | null,
			tab_name: string | null,
			input_name: string,
			value: string,
			rounding?: number,
			country_index?: number,
			save?: boolean,
			calculated = false,
			hard = false
		) => {
			let changed = false;

			if (!hard && country_index !== undefined) {
				const country = allCountries[country_index];
				if (calculated && editedColumns.includes(country + '.' + input_name)) {
					return false;
				}

				if (!calculated && !editedColumns.includes(country + '.' + input_name)) {
					addEditedColumn(country + '.' + input_name);
				}
			}

			if (rounding !== undefined) {
				if (value !== undefined && value.length > 0 && value[0] !== '-') {
					value = '0' + value;
				}
				value = parseFloat(value).toFixed(rounding);
			}

			const d = product as any;

			let needReload = false;

			if (country_index !== undefined) {
				const country = allCountries[country_index];
				needReload =
					structure?.countries
						.get(country)
						?.tabs.find((t) => t.key === tab_name)
						?.columns.find((col) => col.key === input_name)?.need_reload ?? false;
			} else {
				needReload =
					structure?.tabs
						.find((t) => t.key === tab_name)
						?.columns.find((col) => col.key === input_name)?.need_reload ?? false;
			}

			let isCompositionModification = false;

			if (save !== true) {
				if (save === undefined) {
					save = true;
				}

				if (input_name == 'quantity' && value == '0') {
					if (document.activeElement !== null) {
						(document.activeElement as HTMLElement).blur();
					}
					d.items = d.items.filter((_item: any, i: number) => i !== item_id);
					isCompositionModification = true;
				} else {
					if (country_index != undefined) {
						const country = allCountries[country_index];
						const ci = d.countries.findIndex((c: { i_country: string }) => c.i_country === country);
						if (item_id == null && tab_name == null) {
							if (input_name == 'product_name' && namesLinked) {
								d.countries = d.countries.map((c: any) => {
									c.product_name = value;
									return c;
								});
							}
							if (d.countries[ci][input_name] !== value) {
								changed = true;
							}
							d.countries[ci][input_name] = value;
						} else if (tab_name != null && tab_name !== 'tab_GP_calculator') {
							if (d.countries[ci][tab_name][0][input_name] !== value) {
								changed = true;
							}
							d.countries[ci][tab_name][0][input_name] = value;
						} else if (item_id != null) {
							let ici = d.items[item_id].countries.findIndex(
								(c: { i_country: string }) => c.i_country === country
							);
							if (ici == -1) {
								d.items[item_id].countries[country_index] = {
									i_country: country
								};
								ici = d.items[item_id].countries.findIndex(
									(c: { i_country: string }) => c.i_country === country
								);
							}
							if (d.items[item_id].countries[ici][input_name] !== value) {
								changed = true;
							}
							d.items[item_id].countries[ici][input_name] = value;
							if (input_name == 'retail_price') {
								isCompositionModification = true;
							}
						}
					} else {
						if (item_id == null && tab_name == null) {
							if (input_name == 'retail_price') {
								d.countries = d.countries.map((c: any) => {
									const crp =
										typeof c.tab_retail[0].retail_price === 'number'
											? c.tab_retail[0].retail_price.toFixed(2)
											: c.tab_retail[0].retail_price;
									const drp =
										typeof d.retail_price === 'number' ? d.retail_price.toFixed(2) : d.retail_price;
									if (crp == drp || crp == null) {
										c.tab_retail[0].retail_price = parseFloat(value).toFixed(2);
									}
									return c;
								});
							}
							if (d[input_name] !== value) {
								changed = true;
							}
							d[input_name] = value;
						} else if (tab_name != null) {
							d.countries = d.countries.map((c: any) => {
								if (c[tab_name][0][input_name] !== value) {
									changed = true;
								}
								c[tab_name][0][input_name] = value;
								return c;
							});
						} else if (item_id != null) {
							if (d.items[item_id][input_name] !== value) {
								changed = true;
							}
							d.items[item_id][input_name] = value;

							isCompositionModification = true;
						}
					}
				}

				setProduct({ ...d });

				setBaseColumns(
					getBaseColumns(allCountries, d, props.sumPrice, props.listWeekFrom, props.listWeekTo)
				);

				if (product.i_product == null && product.items.some((item) => item.i_item !== '')) {
					isCompositionModification = true;
					d.action = 'insert';
				}
			}

			if (saveTimer !== null) {
				clearTimeout(saveTimer);
			}

			const urlParams = new URLSearchParams(window.location.search);

			if ((product.i_product != null || d.action === 'insert') && save) {
				setSaveTimer(
					setTimeout(
						() => {
							props.setDataSaveStatus('Saving');
							if (isCompositionModification || needReload) {
								setSavingLock(true);
							}
							sendHttpRequest(
								'POST',
								'/promotion/' + (urlParams.get('i_list') ?? '1') + '/save',
								{ json: JSON.stringify(d), i_business: props.i_business },
								navigate,
								dispatch
							).then((response) => {
								setSaveTimer(null);
								if (response.data[1]?.find((log: { n_l: string }) => log.n_l == 'reload_needed')) {
									needReload = true;
								}
								if (
									(isCompositionModification || needReload) &&
									response.data[0][0].prod_json !== undefined
								) {
									const data = JSON.parse(response.data[0][0].prod_json);
									if (data.retail_price !== undefined && data.retail_price !== null) {
										data.retail_price = (data.retail_price as number).toFixed(2);
									} else {
										data.retail_price = '';
									}
									if (data.items === undefined || data.items === null) {
										data.items = [];
									}
									for (const item of data.items) {
										if (item.srp !== undefined) {
											item.srp = ((item.srp ?? 0) as number).toFixed(2);
										}
									}
									data.id = product.id;
									setProduct(data);
									setNamesLinked(
										data.countries.every(
											(c: { product_name: string }) =>
												c.product_name === data.countries[0].product_name
										)
									);
								}
								setSavingLock(false);
								props.setDataSaveStatus('Data saved');
								if (isCompositionModification && response.data[0][0].prod_json !== undefined) {
									setNeedsRecalculation(true);
								}
								getTotalGpPercentage(props.countries, props.iList, navigate, dispatch).then(
									(response: any) => {
										dispatch(setTotalGpPercentage(response.data.gp_percentage as number));
									}
								);
							});
						},
						d.action === 'insert' ? 0 : 1000
					)
				);
			}

			return changed;
		},
		[
			addEditedColumn,
			allCountries,
			dispatch,
			editedColumns,
			namesLinked,
			navigate,
			product,
			props,
			saveTimer,
			structure?.countries,
			structure?.tabs
		]
	);

	const isCreated = useMemo(() => {
		if (product.product_JDE_status === 'SUCCESS') {
			return true;
		}
		if (product.product_JDE_status === 'WAITING') {
			return null;
		}
		return false;
	}, [product.product_JDE_status]);

	const creationStatus = useMemo(() => {
		if (product.product_JDE_status == 'NEW') {
			return 'promotion.item_status.not_created_in_JDE';
		}
		if (product.product_JDE_status == 'WAITING') {
			return 'promotion.item_status.creating_in_JDE';
		}
		if (product.product_JDE_status == 'SUCCESS') {
			return 'promotion.item_status.created_in_JDE';
		}
		if (product.product_JDE_status == 'FAILURE') {
			return 'promotion.item_status.failed_to_create_in_JDE';
		}
		return 'promotion.item_status.' + product.product_JDE_status.toLowerCase();
	}, [product.product_JDE_status]);

	const calculateValues = useCallback(
		(hard = false, lastEditedColumn?: string) => {
			if (hard) {
				setEditedColumns([]);
			}
			let changes = true;
			let i = 0;
			while (changes && i < 10) {
				changes = false;
				i++;
				props.countries.forEach((country) => {
					const baseColumn = getBaseColumns(
						allCountries,
						product,
						props.sumPrice,
						props.listWeekFrom,
						props.listWeekTo
					).find((bc) => bc.country === country);
					countriesTabs?.get(country)?.tabs.forEach((tab) => {
						tab.columns.forEach((col) => {
							if (
								col.definition &&
								baseColumn &&
								col.definition !== 'manual_input' &&
								(hard ||
									!(
										editedColumns.includes(country + '.' + col.key) || lastEditedColumn === col.key
									))
							) {
								if (
									setValue(
										null,
										tab.key,
										col.key,
										calculateValue(baseColumn, col.definition, col.decimal),
										col.decimal,
										allCountries.indexOf(country),
										false,
										true,
										hard
									)
								) {
									changes = true;
								}
							}
						});
					});
				});
			}
			if (i === 10) {
				console.warn(
					'Calculation: max iterations reached - infinite loop detected. Please check definitions.'
				);
			}
			setValue(null, null, '', '', undefined, undefined, true);
		},
		[
			allCountries,
			countriesTabs,
			editedColumns,
			product,
			props.countries,
			props.listWeekFrom,
			props.listWeekTo,
			props.sumPrice,
			setValue
		]
	);

	useEffect(() => {
		if (needsRecalculation) {
			setNeedsRecalculation(false);
			calculateValues(true);
		}
	}, [calculateValues, needsRecalculation]);

	if (!structure) {
		return <></>;
	}

	function getValue(tab_key: string | null, col_key: string, c?: string, rounding?: number) {
		if (tab_key == 'tab_GP_calculator') {
			return '$calc$';
		}
		if (c) {
			const country = product.countries?.find((pc) => pc.i_country === c);
			if (!country) {
				return '';
			}
			if (col_key == 'product_name') {
				return country?.product_name ?? '';
			}
			const tab = (country[tab_key as keyof typeof country] as object[])[0];
			return tab[col_key as keyof typeof tab] ?? '';
		}
		const values = product.countries?.map((pc) => {
			const tab = (pc[tab_key as keyof typeof pc] as object[])[0];
			const val = tab[col_key as keyof typeof tab] as number | string | null;
			if (typeof val === 'number' && rounding !== undefined) {
				return val.toFixed(rounding);
			}
			return tab[col_key as keyof typeof tab] ?? '';
		});
		if (values?.length && values.every((v) => v == values[0])) {
			if (col_key == 'retail_price' && product.retail_price !== values[0]) {
				setProduct({ ...product, retail_price: values[0] });
			}
			return values[0] ?? '';
		}
		return '';
	}

	function addItem() {
		product.items.push({
			c_item: '',
			i_item: '',
			n_item: '',
			quantity: 1,
			srp: '0.00',
			countries: []
		});
		setProduct({ ...product });
	}

	function removeStockItem(itemIndex: number) {
		product.items.splice(itemIndex, 1);
		setProduct({ ...product });
		setValue(null, null, '', '', undefined, undefined, true);
	}

	function removeProduct() {
		const confirmation = confirm(
			`Are you sure you want to ${props.listStatus === 'BUILD' ? 'delete' : 'close'} this product?`
		);
		if (!confirmation) {
			return false;
		}

		const urlParams = new URLSearchParams(window.location.search);

		product.action = 'delete';
		sendHttpRequest(
			'POST',
			'/promotion/' + (urlParams.get('i_list') ?? '1') + '/save',
			{ json: JSON.stringify(product), i_business: props.i_business },
			navigate,
			dispatch
		).then(() => {
			if (props.listStatus === 'BUILD') {
				props.removeProduct(product.id, product.i_template);
			} else {
				setProduct({ ...product, cancelled: true });
			}
		});
	}

	async function copyProduct() {
		const urlParams = new URLSearchParams(window.location.search);
		const response = await sendHttpRequest(
			'POST',
			'/promotion/copy',
			{
				i_list: parseInt(urlParams.get('i_list') ?? '1'),
				i_product: product.i_product,
				i_template: product.i_template,
				action: 'COPY'
			},
			navigate,
			dispatch
		);
		const i_list = response.data.find((d: any[]) => d[0].i_list_target !== undefined)[0]
			.i_list_target;
		dispatch(
			addNotification({
				id: randomHash(),
				message: t('promotion.notifications.product_copied_successfully', { i_list }),
				dismissable: true,
				severity: 'success',
				navigation: '/list_detail?i_list=' + i_list,
				actionText: t('promotion.notifications.open')
			})
		);
	}

	async function replaceProduct() {
		const urlParams = new URLSearchParams(window.location.search);
		const response = await sendHttpRequest(
			'POST',
			'/promotion/copy',
			{
				i_list: parseInt(urlParams.get('i_list') ?? '1'),
				i_product: product.i_product,
				i_template: product.i_template,
				action: 'REPLACE'
			},
			navigate,
			dispatch
		);
		const i_list = response.data.find((d: any[]) => d[0].i_list_target !== undefined)[0]
			.i_list_target;
		dispatch(
			addNotification({
				id: randomHash(),
				message: t('promotion.notifications.product_replaced_successfully', { i_list }),
				dismissable: true,
				severity: 'success',
				navigation: '/list_detail?i_list=' + i_list,
				actionText: t('promotion.notifications.open')
			})
		);
		props.triggerDataRefresh();
	}

	function onNamesLinkedChange() {
		const newVal = !namesLinked;
		setNamesLinked(newVal);

		if (newVal) {
			const d = product as any;
			d.countries = d.countries.map((c: any) => {
				c.product_name = d.countries[0].product_name;
				return c;
			});

			setProduct({ ...d });
		}
	}

	function blurInput(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			e.currentTarget.blur();
		}
	}

	return (
		<div className={'row mx-0 d-flex flex-nowrap' + (savingLock ? ' saving-lock' : '')}>
			{savingLock && <Loading />}
			<div className="promotion-sticky-left pe-2 py-2">
				<div className="products-wrapper">
					<div className="row mx-0">
						<div className="col-3 px-1">
							<Input
								name={props.template + '.' + props.index + '.product_code'}
								label={t('promotion.fields.product_code')}
								type="text"
								disabled={true}
								// disabled={
								// 	savingLock ||
								// 	product.i_product == null ||
								// 	props.editDisabledByStatus ||
								// 	props.listStatus === 'COMPLETED'
								// }
								required={true}
								// onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								// 	setValue(null, null, 'product_price_code', e.target.value)
								// }
								defaultValue={product.product_price_code}
								setDataSaveStatus={props.setDataSaveStatus}
								// onKeyDown={blurInput}
							/>
						</div>

						<div className="col-3 px-1">
							<Input
								name={props.template + '.' + props.index + '.product_short_code'}
								label={
									product.i_template == 'CA'
										? t('promotion.fields.catalogue_code')
										: t('promotion.fields.original_code')
								}
								type="text"
								disabled={
									savingLock ||
									product.i_product == null ||
									props.editDisabledByStatus ||
									props.listStatus === 'COMPLETED'
								}
								required={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setValue(null, null, 'product_short_code', e.target.value)
								}
								defaultValue={product.product_short_code ?? ''}
								setDataSaveStatus={props.setDataSaveStatus}
								onKeyDown={blurInput}
							/>
						</div>

						<div className="col-2 px-1">
							<Input
								name={props.template + '.' + props.index + '.srp_total'}
								label={t('promotion.fields.srp')}
								type="number"
								customClass="text-end"
								min={0}
								disabled={true}
								step={0.01}
								defaultValue={props.sumPrice(product).toFixed(2)}
								required={true}
								setDataSaveStatus={props.setDataSaveStatus}
							/>
						</div>

						<div className="col-3 p-0">
							<div className="row m-0">
								<div className="col-6 px-1">
									<Input
										name={props.template + '.' + props.index + '.retail_price'}
										label={t('promotion.fields.retail_price')}
										type="number"
										customClass="text-end"
										min={0}
										step={0.01}
										disabled={
											savingLock ||
											product.i_product == null ||
											props.editDisabledByStatus ||
											props.listStatus === 'COMPLETED'
										}
										required={true}
										defaultValue={product.retail_price.toString()}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setValue(null, null, 'retail_price', e.target.value, 2, undefined, false);
											calculateValues();
										}}
										rounding={2}
										setDataSaveStatus={props.setDataSaveStatus}
										onKeyDown={blurInput}
									/>
								</div>
								<div className="col-6 px-1">
									<label className="outside-floating-label">
										{t('promotion.fields.base_price')}
									</label>
								</div>
							</div>
						</div>
						<div className="col-1 d-flex justify-content-end pe-0">
							{(!props.editDisabledByStatus || props.listStatus === 'COMPLETED') && (
								<Dropdown align="end">
									<Dropdown.Toggle variant="link" className="btn-icon fs-5">
										<i className="bi bi-three-dots-vertical" />
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item
											disabled={props.editDisabledByStatus || props.listStatus === 'COMPLETED'}
											onClick={() => calculateValues(true)}>
											<i className="bi bi-calculator me-2" />
											<span>{t('promotion.fields.calculate')}</span>
										</Dropdown.Item>
										{props.listStatus === 'COMPLETED' && (
											<>
												<Dropdown.Item
													onClick={() => {
														copyProduct();
													}}>
													<i className="bi bi-copy me-2" />
													<span>{t('promotion.fields.copy')}</span>
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => {
														replaceProduct();
													}}>
													<i className="bi bi-pencil-square me-2" />
													<span>{t('promotion.fields.replace')}</span>
												</Dropdown.Item>
											</>
										)}
										{['BUILD', 'COMPLETED'].includes(props.listStatus) && props.canClose && (
											<>
												<Dropdown.Item
													onClick={() => {
														setSavingLock(true);
														if (!removeProduct()) {
															setSavingLock(false);
														}
													}}>
													{props.listStatus === 'BUILD' && (
														<>
															<i className="bi bi-trash3 me-2" />
															<span>{t('promotion.fields.delete')}</span>
														</>
													)}
													{props.listStatus === 'COMPLETED' && (
														<>
															<i className="bi bi-x-circle me-2" />
															<span>{t('promotion.fields.close')}</span>
														</>
													)}
												</Dropdown.Item>
											</>
										)}
									</Dropdown.Menu>
								</Dropdown>
							)}
						</div>

						{props.countries.map((c, i) => (
							<div className="col-12 mt-2" key={i}>
								<div className="row align-items-center">
									<div className="col-1 px-2 country-label">{c}</div>
									<div className="col-7 px-1 position-relative">
										{i === 0 && !props.editDisabledByStatus && (
											<button
												type="button"
												tabIndex={-1}
												className="btn names-linked-toggle"
												onClick={onNamesLinkedChange}>
												<i
													className={
														'fs-5 bi bi-link-45deg ' +
														(namesLinked ? 'color-primary-shade' : 'color-gray')
													}
												/>
											</button>
										)}
										<PromotionCell
											template={props.template}
											productIndex={props.index}
											productId={product.id}
											country={c}
											name={'product_name'}
											type={'text'}
											maxLength={30}
											required={true}
											disabled={
												savingLock ||
												product.i_product == null ||
												(namesLinked && i > 0) ||
												props.editDisabledByStatus
											}
											getValue={getValue}
											setValue={setValue}
											setDataSaveStatus={props.setDataSaveStatus}
										/>
									</div>
									<div className="col-3 p-0">
										<div className="row m-0">
											<div className="col-6 px-1">
												<PromotionCell
													template={props.template}
													productIndex={props.index}
													productId={product.id}
													name="country_retail_price"
													type="number"
													customClass="text-end"
													required={true}
													setValue={setValue}
													disabled={true}
													setDataSaveStatus={props.setDataSaveStatus}
													defaultValue={product.items
														.map((item) => {
															const itemCountry = item.countries?.find((pc) => pc.i_country === c);
															return (
																parseInt(item.quantity.toString()) *
																parseFloat((itemCountry?.retail_price ?? 0).toString())
															);
														})
														.reduce((acc, item) => acc + item, 0)
														.toFixed(2)}
												/>
											</div>
											<div className="col-6 px-1">
												<PromotionCell
													template={props.template}
													productIndex={props.index}
													productId={product.id}
													name="country_base_price"
													type="number"
													customClass="text-end"
													required={true}
													setValue={setValue}
													disabled={true}
													setDataSaveStatus={props.setDataSaveStatus}
													defaultValue={product.items
														.map((item) => {
															const itemCountry = item.countries?.find((pc) => pc.i_country === c);
															return (
																parseInt(item.quantity.toString()) *
																parseFloat((itemCountry?.base_price ?? 0).toString())
															);
														})
														.reduce((acc, item) => acc + item, 0)
														.toFixed(2)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}

						{props.showStockItems && (
							<div className="col-12 mt-2 mx-0">
								<div className="row stock-item-header-labels">
									<div className="col-1 ps-3 pe-0">{t('promotion.fields.qty')}</div>
									<div className="col-5 px-3 d-flex justify-content-between">
										{t('promotion.fields.stock_item')}
										{!(props.editDisabledByStatus || props.listStatus === 'COMPLETED') && (
											<button
												type="button"
												className="link"
												disabled={savingLock}
												onClick={() => {
													if (!savingLock) {
														addItem();
													}
												}}>
												+&nbsp;{t('promotion.fields.add')}
											</button>
										)}
									</div>
									<div className="col-2"></div>
									<div
										className={
											'col-3 text-center approval-status fw-medium ' +
											(isCreated
												? 'color-green-dark'
												: isCreated == null
													? 'color-yellow-dark'
													: 'color-red-dark')
										}>
										{t(creationStatus)}
									</div>
								</div>

								{product.items.map((item, i) => (
									<PromotionStockItem
										key={i}
										template={props.template}
										productId={product.id}
										productIndex={props.index}
										i={i}
										item={item}
										itemsLength={product.items.length}
										disabled={
											savingLock || props.editDisabledByStatus || props.listStatus === 'COMPLETED'
										}
										setValue={setValue}
										setDataSaveStatus={props.setDataSaveStatus}
										removeStockItem={removeStockItem}
										calculateValues={calculateValues}
										countries={props.countries}
									/>
								))}
							</div>
						)}
					</div>
					{product.cancelled && <div className="closed-product-fade" />}
					{product.cancelled && <p className="closed-product-label">CLOSED</p>}
				</div>
			</div>
			<div className="col-7 products-detail-wrapper ps-2 py-2">
				<div className="tab-wrapper mt-0">
					<div className="d-flex p-0 m-0">
						{structure.tabs?.map((t, i) => (
							<Fragment key={i}>
								{t.columns.map((col, col_index) => (
									<Fragment key={col_index}>
										{!props.omitColumns.includes(col.key) && (
											<div
												className={'col px-1' + (col.type == 'datetime-local' ? ' datetime' : '')}>
												{col.type !== 'checkbox' && (
													<>
														<Input
															label={col.name}
															name={props.template + '.' + props.index + '.' + i + '.' + col.key}
															type={col.type}
															customClass={
																['number', 'percentage'].includes(col.type) ? 'text-end' : ''
															}
															step={['number', 'percentage'].includes(col.type) ? 0.01 : undefined}
															defaultValue={getValue(t.key, col.key, undefined, col.decimal)}
															thousandsSeparator={col.no_separator ? null : undefined}
															required={false}
															disabled={
																savingLock ||
																product.i_product == null ||
																col.disabled ||
																t.key === 'tab_GP_calculator' ||
																props.editDisabledByStatus ||
																product.cancelled ||
																(props.listStatus === 'COMPLETED' && !col.open_for_completed_state)
															}
															rounding={col.decimal}
															onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
																setValue(
																	null,
																	t.key,
																	col.key,
																	e.target.value + (col.type == 'datetime-local' ? ':00' : ''),
																	col.decimal,
																	undefined,
																	baseColumnNames.includes(col.key.toLowerCase())
																		? false
																		: undefined
																);
																if (baseColumnNames.includes(col.key.toLowerCase())) {
																	calculateValues(false, col.key);
																}
															}}
															onKeyDown={blurInput}
															setDataSaveStatus={props.setDataSaveStatus}
														/>
														{col.type === 'percentage' && <div>%</div>}
													</>
												)}
												{col.type === 'checkbox' && (
													<Checkbox
														label={col.name}
														name={props.template + '.' + props.index + '.' + i + '.' + col.key}
														defaultValue={
															typeof getValue(t.key, col.key) === 'boolean'
																? getValue(t.key, col.key)
																	? '1'
																	: '0'
																: getValue(t.key, col.key)
														}
														required={false}
														disabled={
															savingLock ||
															product.i_product == null ||
															col.disabled ||
															props.editDisabledByStatus ||
															product.cancelled ||
															(props.listStatus === 'COMPLETED' && !col.open_for_completed_state)
														}
														onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
															setValue(null, t.key, col.key, e.target.value)
														}
													/>
												)}
											</div>
										)}
									</Fragment>
								))}
							</Fragment>
						))}
					</div>
					{props.countries.map((c, c_index) => (
						<div key={c_index} className="d-flex pt-2 px-0">
							{countriesTabs?.get(c)?.tabs.map((t, i) => (
								<Fragment key={i}>
									{t.columns.map((col, col_index) => (
										<Fragment key={col_index}>
											{!props.omitColumns.includes(col.key) && (
												<div
													className={
														'col px-1' + (col.type == 'datetime-local' ? ' datetime' : '')
													}>
													<PromotionCell
														template={props.template}
														productIndex={props.index}
														productId={product.id}
														country={c}
														name={col.key}
														type={col.type}
														step={['number', 'percentage'].includes(col.type) ? 0.01 : undefined}
														required={false}
														disabled={
															savingLock ||
															product.i_product == null ||
															col.disabled ||
															props.editDisabledByStatus ||
															product.cancelled ||
															(props.listStatus === 'COMPLETED' && !col.open_for_completed_state)
														}
														thousandsSeparator={col.no_separator ? null : undefined}
														definition={col.definition}
														rounding={col.decimal}
														calculationBase={baseColumns.find((bc) => bc.country === c)}
														defaultValue={getValue(t.key, col.key, c)}
														setValue={setValue}
														save={
															baseColumnNames.includes(col.key.toLowerCase()) ? false : undefined
														}
														customClass={
															col.type == 'number'
																? 'text-end'
																: col.type == 'percentage'
																	? 'text-end percentage'
																	: ''
														}
														tabName={t.key}
														onChange={() => {
															if (baseColumnNames.includes(col.key.toLowerCase())) {
																calculateValues(false, col.key);
															}
														}}
														addEditedColumn={addEditedColumn}
														removeEditedColumn={removeEditedColumn}
														setDataSaveStatus={props.setDataSaveStatus}
													/>
												</div>
											)}
										</Fragment>
									))}
								</Fragment>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
